"use strict";
+(function(a, b) {
    var v = {
        name: "Vital",
        version: "1.0.0"
    };

    (v.init = function() {
        v.slider(),
            v.formValidator(),
            v.chosenInit(),
            v.countrySelect(),
            v.radioBox();
    }),
        (v.slider = function() {
            a("#slider").slick({
                infinite: false,
                autoplay: true,
                fade: true
            });
        }),
        (v.formValidator = function() {
            a(".default-validate").each(function() {
                a(this).validate();
                a(this).on("submit", function(e) {
                    e.preventDefault();
                    if (a(this).valid()) {
                        a(this.submit());
                    }
                });
            });

            a(".react-validate").each(function() {
                a(this).validate();
                a(this).on("submit", function(e) {
                    e.preventDefault();
                    if (a(this).valid()) {
                        return true;
                    }
                });
            });
        }),
        (v.chosenInit = function() {
            a(".chosen-select").each(function() {
                $(this).chosen();
            });
        }),
        (v.countrySelect = function() {
            a("#countrySelect")
                .chosen()
                .change(function() {
                    var country_code = $(this).val();
                    var data = { country_code: country_code };
                    v.handleFormSubmit(
                        "GET",
                        "/options/get-states",
                        data,
                        setStatesOptions
                    );

                    function setStatesOptions(states) {
                        $("#stateSelect")
                            .empty()
                            .append('<option value=""></option>');
                        a.each(states, function(idx, item) {
                            a("<option/>")
                                .text(item.name)
                                .attr("value", item.state_code)
                                .appendTo("#stateSelect");
                        });
                        $("#stateSelect").trigger("chosen:updated");
                    }
                });
        }),
        (v.handleFormSubmit = function(type, url, data, callback) {
            return a.ajax({
                type: type,
                url: url,
                dataType: "json",
                data: data,
                // processData: false,

                beforeSend: function(xhr, settings) {
                    xhr.setRequestHeader(
                        "x-csrf-token",
                        a('meta[name="csrf-token"]').attr("content")
                    );
                },

                success: function(data, textStatus, xhr) {
                    if (typeof callback === "function") {
                        callback(data);
                    }
                    return data;
                },

                error: function(xhr, status, err) {}
            });
        }),
        (v.radioBox = function() {
            a("#createShipment").on("change", "input.radio-box-el", function() {
                a(".radio-box").removeClass("active");

                if (this.checked) {
                    a(this)
                        .closest(".radio-box")
                        .addClass("active");
                }
            });
        }),
        (b.vital = v);
})(jQuery, window),
    $(function() {
        vital.init();
    });

jQuery(document).ready(function($) {
    $.ajaxSetup({
        headers: {
            "X-CSRF-TOKEN": $('meta[name="_token"]').attr("content")
        }
    });
});

jQuery(document).ready(function($) {
    $("input[name=billing_choice]:radio").change(function() {
        if ($(this).val() == "option3") {
            $("#billing_info").fadeIn("fast");
            return;
        }

        $("#billing_info").fadeOut("fast");
    });

    $("#request-quote").validate({
        submitHandler: function(form) {
            form.submit();
        }
    });

    $('[data-toggle="tooltip"]').tooltip();


    window.pageAlerts = function(type, message) {
        if (type == "") {
            $("#ajax-alerts").empty();
            return false;
        }
        $("#ajax-alerts").empty();
        $("html, body").animate(
            {
                scrollTop: $("#ajax-alerts").offset().top
            },
            300
        );
        $("#ajax-alerts").append(
            '<div class="alert alert-' + type + '"><p>' + message + "</p></div>"
        );
    };

    if (document.getElementById('map')) {
        var map;
        bounds = new google.maps.LatLngBounds();

        map = new GMaps({
            div: "#map",
            zoom: 20,
            lat: 0,
            lng: 0
        });

        $(".pan-to-marker").click(function() {
            var lat, lng;
            var $index = $(this).data("marker-index");
            var $lat = $(this).data("marker-lat");
            var $lng = $(this).data("marker-lng");

            if ($index != undefined) {
                // using indices
                var position = map.markers[$index].getPosition();
                lat = position.lat();
                lng = position.lng();
            } else {
                // using coordinates
                lat = $lat;
                lng = $lng;
            }

            map.setCenter(lat, lng);
            map.setZoom(20);
        });

        $("input[id=markers]").each(function(index, element) {
            var geocode = $(this)
                .val()
                .toString()
                .split(";");

            var cLat = geocode[0];
            var cLng = geocode[1];

            if (index == 0) {
                map = new GMaps({
                    div: "#map",
                    zoom: 20,
                    lat: cLat,
                    lng: cLng
                });
            }

            map.addMarker({
                lat: cLat,
                lng: cLng
            });

            loc = new google.maps.LatLng(cLat, cLng);

            bounds.extend(loc);
        });

        map.fitBounds(bounds);
        map.panToBounds(bounds);
    }
});
